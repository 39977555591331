
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import Utils from "@/utils/Utils";
import { StationService } from "@/services/attendance/station/StationService";
import { log } from "console";
import loginPageVue from "@/views/login/loginPage.vue";
export default defineComponent({
  name: "stationPlace",
  components: {
    HeaderModal,
  },
  props: {
    place: Object,
  },
  data() {
    return {
      api: new StationService(),
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["50px"],
      list: new Array<any>(),
      placeObj: Object.create(null),
      searchParams: {
        page: 1,
        size: 10,
        keyword: "",
      },
      showNoDate: false,
    };
  },
  mounted() {
    this.placeObj = this.place;
    this.initData();
  },
  methods: {
    initData() {
      this.resetPage();
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.page = 1;
      this.getListRequest((res: any) => {
        this.list = res;
        this.showNoDate = res == null || res.length <= 0;
      });
    },
    nextPage(event: any) {
      this.searchParams.page++;
      this.getListRequest((result: any) => {
        if (result) {
          this.list = this.list.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    getListRequest(callback: any) {
      let params = {
        page: this.searchParams.page,
        size: this.searchParams.size,
        keyword: this.searchParams.keyword,
      };
      this.api
        .getMyPlaceList(params)
        .then((res: any) => {
          // this.islist = false;
          callback(res);
        })
        .catch(() => {
          // this.islist = false;
        });
    },
    selectPlace(item: any) {
      this.placeObj = item;
    },
    closeModel(type: number) {
      if (type == 4) {
        if ("id" in this.placeObj) {
          modalController.dismiss({ placeObj: this.placeObj });
        } else {
          Utils.presentToastWarning("请选择一个驻场地点");
        }
      } else {
        modalController.dismiss();
      }
    },
  },
});
