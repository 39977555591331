
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import stationPlace from "@/components/attendance/stationPlace.vue";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import moment from "moment";
import regExps from "@/utils/regExps";
import { StationService } from "@/services/attendance/station/StationService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
export default defineComponent({
  name: "ondutyApply",
  components: {
    Header,
  },
  data() {
    return {
      router: useRouter(),
      api: new StationService(),
      workflowService: new WorkflowService(),
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["50px"],
      placeName: "",
      placeAddress: "",
      placeId: "",
      reason: "",
      startDate: "",
      endDate: "",
      datetimeMax: "",
      datetimeMin: "",
    };
  },
  mounted() {
    this.datetimeMin = moment(new Date()).format("yyyy-MM-DD") + "";
    let dateMin = new Date(this.datetimeMin);
    let dateMax = new Date(dateMin);
    dateMax.setFullYear(dateMax.getFullYear() + 5);
    dateMax.setDate(dateMax.getDate() - 5);
    this.datetimeMax = moment(dateMax).format("yyyy-MM-DD") + "";
  },
  methods: {
    async pickStationPlace() {
      let obj = {
        name: this.placeName,
        id: this.placeId,
        address: this.placeAddress,
      };
      const modal = await modalController.create({
        component: stationPlace,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: { place: obj },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data) {
        this.placeName = data.placeObj.name;
        this.placeId = data.placeObj.id;
        this.placeAddress = data.placeObj.address;
      }
    },
    //保存
    saveHandler() {
      if (this.startDate == "") {
        Utils.presentToastWarning("请选择驻场开始时间");
        return;
      }
      if (this.endDate == "") {
        Utils.presentToastWarning("请选择驻场结束时间");
        return;
      }
      if (this.placeId == "") {
        Utils.presentToastWarning("请选择驻场地点");
        return;
      }
      if (this.reason.replace(/\s+/g, "") == "") {
        Utils.presentToastWarning("请输入驻场原因");
        return;
      }

      if (regExps.SpecChar.test(this.reason)) {
        Utils.presentToastWarning("驻场原因不能输入特殊字符哦！");
        return;
      }
      this.startDate = moment(this.startDate).format("YYYY-MM-DD 00:00:00");
      this.endDate = moment(this.endDate).format("YYYY-MM-DD 00:00:00");
      if (this.startDate > this.endDate) {
        Utils.presentToastWarning("驻场开始时间不能晚于驻场结束时间");
        return;
      }
      let that = this;
      Utils.confirmDialog("确认", "请确认是否提交驻场申请", function () {
        that.submit();
      });
    },
    submit() {
      let params = {
        placeid: this.placeId,
        remark: this.reason,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      this.api
        .submit(params)
        .then((res: any) => {
          if (res.status == 200) {
            let paramSend = {
              procId: res.data.procId,
              taskId: res.data.taskId,
              listReciveVar: [],
              option: "",
            };
            this.workflowService
              .send(paramSend)
              .then((result: any) => {
                if (result) {
                  if (result.result) {
                    Utils.presentToast("已成功提交驻场申请！");
                    setTimeout(() => {
                      this.router.go(-1);
                    }, 500);
                  }
                }
              })
              .catch((ex: any) => {});
          }
        })
        .catch((err: any) => {
          Utils.presentToastWarning(err.data.error);
        });
    },
  },
});
